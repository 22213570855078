<style lang="less">
  .server-progress-data {
    display: flex;
    flex-direction: column;
    .server-progress-title {
      color: #000;
      font-size: 16px;
      padding: 10px;
      font-weight: 800;
    }
    .server-progress-action {
      display: flex;
      align-items: center;
      & > * {
        margin-right: 10px;
      }
    }
    .server-progress-data-echarts {
      flex: 1;
    }
  }
</style>

<template>
  <div class="server-progress-data">
    <div style="display: flex; justify-content: space-between;">
      <div class="server-progress-title">服务器与进程性能</div>
      <div class="server-progress-action">
        <fm-select placeholder="进程数据" v-model="pid" clearable filterable>
          <fm-option v-for="ps in psList" :key="ps.id" :value="ps.pid">{{ps.pid}}/{{(ps.cpu * 100).toFixed(2)}}%/{{(ps.ram * 100).toFixed(2)}}%</fm-option>
        </fm-select>
        <fm-date-picker
          v-model="timerange"
          :shortcut="shortcut"
          placeholder="请选择"
          type="datetimerange"
        ></fm-date-picker>
      </div>
    </div>
    <div class="server-progress-data-echarts" ref="echatrs"></div>
  </div>
</template>

<script>
import { serverRequest } from '@/api'
import { loadServerStatus, registerReloadTimer, removeReloadTimer } from '../lib'

const echarts = require('echarts')

export default {
  props: {
    id: { type: Number, default: null }
  },
  data () {
    return {
      data: null,
      dataList: [],
      pid: null,
      timerange: [
        new this.$datetime.Date().compute('h', -6).format('Y-M-D H:I:S'),
        new this.$datetime.Date().format('Y-M-D H:I:S')
      ],
      chart: null,
      shortcut: [
        {
          text: '近6小时',
          value (XDate) {
            return [new XDate().compute("i", -6), new XDate()]
          }
        },
        {
          text: '今天',
          value (XDate) {
            return [new XDate(new XDate().format('Y-M-D 00:00:00')), new XDate()]
          }
        },
        {
          text: '昨天',
          value (XDate) {
            return [new XDate(new XDate().compute("d", -1).format('Y-M-D 00:00:00')), new XDate((new XDate().format('Y-M-D 00:00:00')))]
          }
        },
        {
          text: '近三天',
          value (XDate) {
            return [new XDate().compute("d", -3), new XDate((new XDate().format('Y-M-D 00:00:00')))]
          }
        },
        {
          text: '近七天',
          value (XDate) {
            return [new XDate().compute("d", -7), new XDate((new XDate().format('Y-M-D 00:00:00')))]
          }
        }
      ]
    }
  },
  watch: {
    query: {
      immediate: true,
      deep: true,
      handler () {
        this.loadData()
      }
    },
    id: {
      immediate: true,
      deep: true,
      handler () {
        this.loadPsData()
      }
    },
    options: {
      deep: true,
      handler () {
        this.draw()
      }
    }
  },
  computed: {
    serverPsOptions () {
      let collectTime = this.dataList.map(v => v.collectTime)
      let days = []
      let daysflag = collectTime.reduce((a, b) => {
        let day = b.substr(0, 10)
        if (!days.includes(day)) {
          days.push(day)
          a.push(b)
        }
        return a
      }, [])
      days = null
      return {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return [
              params[0].data.name, ...params.map(v => {
                return v.marker + v.seriesName + ': ' + v.value + '%'
              })
            ].join("<br />")
          }
        },
        grid: {
          top: '30px',
          bottom: '20px'
        },
        legend: {
          icon: 'roundRect',
          itemHeight: 4,
          itemWidth: 10,
          right: '20px'
        },
        dataZoom: [
          { start: 0, height: 15 },
          { type: 'inside', zoomLock: true },
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: collectTime,
          axisLabel: {
            formatter: (v) => {
              return daysflag.includes(v) ? this.$datetime.format(v, 'D日 H:I:S') : this.$datetime.format(v, 'H:I:S')
            }
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'CPU',
            type: 'line',
            smooth: true,
            markLine: {
              symbol: ['none', 'none'],
              label: { show: true, formatter: v => v.name },
              lineStyle: {
                width: 1,
                shadowColor: 'rgba(0, 0, 0, .5)',
                shadowBlur: 10,
                type: 'dashed'
              },
              data: daysflag.map(v => {
                return {name: v.substr(5, 10), xAxis: v}
              })
            },
            data: this.dataList.map(v => {
              return {
                name: v.pid + ' - ' + v.collectTime,
                value: (v.cpu * 100).toFixed(2),
                data: v
              }
            })
          },
          {
            name: '内存',
            type: 'line',
            smooth: true,
            data: this.dataList.map(v => {
              return {
                name: v.pid + ' - ' + v.collectTime,
                value: (v.ram * 100).toFixed(2),
                data: v
              }
            })
          }
        ]
      }
    },
    serverOptions () {
      let collectTime = this.dataList.map(v => v.collectTime)
      let days = []
      let daysflag = collectTime.reduce((a, b) => {
        let day = b.substr(0, 10)
        if (!days.includes(day)) {
          days.push(day)
          a.push(b)
        }
        return a
      }, [])
      days = null
      return {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return [
              params[0].data.name, ...params.map(v => {
                return v.marker + v.seriesName + ': ' + v.value + '%'
              })
            ].join("<br />")
          }
        },
        grid: {
          top: '30px',
          bottom: '20px'
        },
        legend: {
          icon: 'roundRect',
          itemHeight: 4,
          itemWidth: 10,
          right: '20px'
        },
        dataZoom: [
          { start: 0, height: 15 },
          { type: 'inside', zoomLock: true },
        ],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: collectTime,
          axisLabel: {
            formatter: (v) => {
              return daysflag.includes(v) ? this.$datetime.format(v, 'D日 H:I:S') : this.$datetime.format(v, 'H:I:S')
            }
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '磁盘空间',
            type: 'line',
            smooth: true,
            markLine: {
              symbol: ['none', 'none'],
              label: { show: true, formatter: v => v.name },
              lineStyle: {
                width: 1,
                shadowColor: 'rgba(0, 0, 0, .5)',
                shadowBlur: 10,
                type: 'dashed'
              },
              data: daysflag.map(v => {
                return {name: v.substr(5, 10), xAxis: v}
              })
            },
            data: this.dataList.map(v => {
              return {
                name: v.collectTime,
                value: (v.diskSpace * 100).toFixed(2),
                data: v
              }
            })
          },
          {
            name: 'CPU',
            type: 'line',
            smooth: true,
            data: this.dataList.map(v => {
              return {
                name: v.collectTime,
                value: (v.cpu * 100).toFixed(2),
                data: v
              }
            })
          },
          {
            name: '内存',
            type: 'line',
            smooth: true,
            data: this.dataList.map(v => {
              return {
                name: v.collectTime,
                value: (v.ram * 100).toFixed(2),
                data: v
              }
            })
          }
        ]
      }
    },
    options () {
      return this.pid ? this.serverPsOptions :  this.serverOptions
    },
    query () {
      let start = this.timerange[0] || ''
      let end = this.timerange[1] || ''
      let res = {
        serverId: this.id,
        minCollectTime: typeof start === 'string' ? start : start.format('Y-M-D H:I:S'),
        maxCollectTime: typeof end === 'string' ? end : end.format('Y-M-D H:I:S')
      }

      if (this.pid) {
        res.pid = this.pid
      }

      return res
    },
    psList () {
      return this.data ? this.data.serverPsStatus : []
    }
  },
  methods: {
    async loadData (refresh = false) {
      if (this.pid) {
        this.dataList = await serverRequest.get_server_ps_status(this.query, refresh)
      } else {
        this.dataList = await serverRequest.get_server_status(this.query, refresh)
      }
      this.draw()
    },
    draw () {
      if (this.dataList.length) {
        this.chart = this.chart ? this.chart : echarts.init(this.$refs.echatrs)
        this.chart.setOption(this.options, true)
        if (this.pid) {
          this.chart.off('dblclick', this.showPidDetails).on('dblclick', this.showPidDetails)
        }
      } else {
        this.chart && this.chart.clear()
        this.chart = null
      }
    },
    async loadPsData (refresh = false) {
      this.data = await loadServerStatus({serverId: this.id}, refresh)
    },
    showPidDetails (params) {
      this.$dialog.info({
        title: params.data.data.pid + ' - 进程详情',
        content: params.data.data.command
      })
    },
    reload () {
      this.loadData(true)
      this.loadPsData(true)
    }
  },
  mounted () {
    registerReloadTimer(this.reload, 600 * 1000)
  },
  destroyed () {
    removeReloadTimer(this.reload)
  }
}
</script>
