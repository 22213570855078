<style lang="less">
  .server-progress-status {
    display: flex;
    flex-direction: column;
    .server-progress-status-echarts {
      display: flex;
      flex-direction: column;
      flex: 1;
      .server-progress-status-cpu, .server-progress-status-ram {
        flex: 1;
      }
    }
    .server-progress-title {
      color: #000;
      font-size: 16px;
      padding: 10px;
      font-weight: 800;
    }
  }
</style>

<template>
  <div class="server-progress-status">
    <div class="server-progress-title">当前进程状态</div>
    <div class="server-progress-status-echarts">
      <div class="server-progress-status-cpu" ref="cpu"></div>
      <div class="server-progress-status-ram" ref="ram"></div>
    </div>
  </div>
</template>

<script>
import { loadServerStatus, registerReloadTimer, removeReloadTimer } from '../lib'
const echarts = require('echarts')

function getOption (name, data, getItem) {
  return {
    title: {
      text: name,
      left: '17%',
      bottom: 'center'
    },
    grid: {
      left: '10'
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `${params.seriesName} - 进程详情<br />
        <div style="display: flex;align-items: center;">
          ${params.marker} <div style="max-width: 150px; word-break: break-all; white-space: normal;">${params.data.name}${params.data.command ? ('  -  ' + params.data.command) : ''}</div>
        </div>`
      }
    },
    legend: {
      right: '20px',
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      textStyle: {
        width: 130,
        overflow: 'truncate',
        rich: {
          name: {
            color: '#657180'
          },
          split: {
            color: '#D9D9D9'
          },
          rate: {
            color: '#aaa'
          }
        }
      },
      formatter: function(name) {
        let item = getItem(name)
        return `{name|${item.name}}  {split||}  {rate|${item.value}%}`
      }
    },
    series: [{
      name: name,
      type: 'pie',
      radius: ['55%', '75%'],
      left: 0,
      center: ['30%', '50%'],
      avoidLabelOverlap: false,
      label: {
        show: false
      },
      labelLine: {
        show: false
      },
      data: data
    }]
  }
}

export default {
  props: {
    id: { type: Number, default: null }
  },
  data () {
    return {
      data: null,
      cpuChart: null,
      ramChart: null
    }
  },
  watch: {
    id: {
      immediate: true,
      handler () {
        this.loadData()
      }
    }
  },
  computed: {
    used () {
      let data = this.data.serverPsStatus
      let num = 5
      let cpu = [...new Array(num)].map((v, i) => {return {name: '', value: (i >= num - 2) ? 0 : -1, command: ''}})
      let ram = [...new Array(num)].map((v, i) => {return {name: '', value: (i >= num - 2) ? 0 : -1, command: ''}})

      for (let i = data.length - 1; i >= 0; i--) {
        let item = data[i]
        let cpuFlag = false
        let ramFlag = false
        for (let l = 0; l < num; l++) {
          if (l === num - 1) {
            break
          } else if (l === num - 2) {
            if (!cpuFlag) {
              cpu[l].value += item.cpu
            }
            if (!ramFlag) {
              ram[l].value += item.ram
            }
          } else {
            if (!cpuFlag && cpu[l].value < item.cpu) {
              cpu[l].name = item.pid
              cpu[l].value = item.cpu
              cpu[l].command = item.command
              cpuFlag = true
            }
            if (!ramFlag && ram[l].value < item.ram) {
              ram[l].name = item.pid
              ram[l].value = item.ram
              ram[l].command = item.command
              ramFlag = true
            }
          }
        }
      }

      cpu[num - 2].name = '其他'
      ram[num - 2].name = '其他'

      cpu[num - 1].value = 1 - (cpu.slice(0, num - 1).reduce((a, b) => a + b.value, 0))
      ram[num - 1].value = 1 - (ram.slice(0, num - 1).reduce((a, b) => a + b.value, 0))

      cpu[num - 1].value = cpu[num - 1].value < 0 ? 0 : cpu[num - 1].value
      ram[num - 1].value = ram[num - 1].value < 0 ? 0 : ram[num - 1].value

      cpu[num - 1].name = '空闲'
      ram[num - 1].name = '空闲'

      return {
        cpu: cpu.map(v => {
          return { name: v.name, value: Number((v.value * 100).toFixed(2)), command: v.command }
        }),
        ram: ram.map(v => {
          return { name: v.name, value: Number((v.value * 100).toFixed(2)), command: v.command }
        })
      }
    },
    cpuChartOptions () {
      return getOption('CPU占用情况', this.used.cpu, (name) => {
        return this.used.cpu.find(v => v.name === name)
      })
    },
    ramChartOptions () {
      return getOption('内存占用情况', this.used.ram, (name) => {
        return this.used.ram.find(v => v.name === name)
      })
    }
  },
  methods: {
    async loadData (refresh = false) {
      this.data = await loadServerStatus({serverId: this.id}, refresh)
      this.draw()
    },
    draw () {
      if (this.data) {
        this.cpuChart = this.cpuChart ? this.cpuChart : echarts.init(this.$refs.cpu)
        this.cpuChart.setOption(this.cpuChartOptions)
        this.cpuChart.off('dblclick', this.dblclick).on('dblclick', this.dblclick)

        this.ramChart = this.ramChart ? this.ramChart : echarts.init(this.$refs.ram)
        this.ramChart.setOption(this.ramChartOptions)
        this.ramChart.off('dblclick', this.dblclick).on('dblclick', this.dblclick)
      } else {
        this.cpuChart && this.cpuChart.clear()
        this.ramChart && this.ramChart.clear()
        this.cpuChart = this.ramChart = null
      }
    },
    dblclick (params) {
      this.$dialog.info({
        title: params.data.name + ' - ' + params.seriesName + ' - ' + params.data.value + '%',
        content: params.data.command
      })
    },
    reload () {
      this.loadData(true)
    }
  },
  mounted () {
    registerReloadTimer(this.reload, 600 * 1000)
  },
  destroyed () {
    removeReloadTimer(this.reload)
  }
}
</script>
