<style lang="less">
  .server-waning {
    display: flex;
    flex-direction: column;
    background-color: #F8FBFF;
    .fm-tabs {
      background-color: #FFF;
    }
    .fm-tabs-content {
      display: none;
    }
    .server-waning-panes {
      .fm-tabs-nav-item {
        width: 190px;
        // height: 51px;
        // line-height: 51px;
        padding: 0;
      }
    }
  }
</style>

<template>
  <div class="server-waning">
    <fm-tabs @change="(index) => showType = index" class="server-waning-panes">
      <fm-tab-pane v-for="pane in panes" :key="pane.key" :name="pane.key" :title="pane.label"></fm-tab-pane>
    </fm-tabs>
    <div style="flex: 1;position: relative;margin-top: 10px;">
      <fm-table-new size="small" :full="false" auto-height :key="showType" :stripe="false" border="row" :columns="columns" :data-list="dataList"></fm-table-new>
    </div>
  </div>
</template>

<script>
import { loadServerStatus } from './lib'

function render (h, value) {
  return h('div', (value * 100).toFixed(2) + '%')
}

const panes = Object.freeze([
  {
    label: '服务器预警',
    key: 'server'
  },
  {
    label: '进程预警',
    key: 'ps'
  }
])

export default {
  data () {
    return {
      status: null,
      showType: this.type,
      panes: panes
    }
  },
  props: {
    type: { type: String, default: 'server' },
    id: { type: Number, default: null }
  },
  watch: {
    id: {
      immediate: true,
      handler () {
        this.loadStatus()
      }
    }
  },
  computed: {
    columns () {
      return this.showType === 'server' ? [
        { title: '时间', field: 'collectTime' },
        {
          title: 'CPU', field: 'cpu', dataType: Number, sort: true, render (h, row) {
            return render(h, row.cpu)
          }
        },
        {
          title: '内存', field: 'ram', dataType: Number, sort: true, render (h, row) {
            return render(h, row.ram)
          }
        },
        {
          title: '硬盘', field: 'diskSpace', dataType: Number, sort: true, render (h, row) {
            return render(h, row.diskSpace)
          }
        }
      ] : [
        { title: '时间', field: 'collectTime' },
        { title: '进程号', field: 'pid' },
        {
          title: 'CPU', field: 'cpu', dataType: Number, sort: true, render (h, row) {
            return render(h, row.cpu)
          }
        },
        {
          title: '内存', field: 'ram', dataType: Number, sort: true, render (h, row) {
            return render(h, row.ram)
          }
        },
        { title: '命令', field: 'command' }
      ]
    },
    dataList () {
      if (this.status) {
        return this.showType === 'server' ? this.status.warningServerStatus.map(v => {
          return {
            collectTime: v.collectTime,
            cpu: v.cpu,
            ram: v.ram,
            diskSpace: v.diskSpace
          }
        }) : this.status.warningServerPsStatus.map(v => {
          return {
            collectTime: v.collectTime,
            cpu: v.cpu,
            ram: v.ram,
            pid: v.pid,
            command: v.command
          }
        })
      } else {
        return []
      }
    }
  },
  methods: {
    async loadStatus () {
      this.status = await loadServerStatus({serverId: this.id})
    }
  }
}
</script>
