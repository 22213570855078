<style lang="less">
  .server-monitor {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {
      background-color: #FFF;
      padding: 20px;
      position: relative;
    }
    .content {
      margin: 20px;
      overflow: auto;
      flex: 1;
      position: relative;
      .progress {
        height: 100%;
        display: flex;
        .status, .data {
          background-color: #FFF;
          border-radius: 6px;
        }
        .status, .data {
          height: calc(100vh - 56px - 130px - 40px);
        }
        .status {
          width: 35%;
        }
        .data {
          flex: 1;
          margin-left: 20px;
          position: relative;
          & > * {
            height: 50%;
          }
        }
      }
      .disk {
        margin-top: 20px;
        height: 100%;
      }
    }
    .warning-log-btn {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    .infos {
      margin-top: 10px;
      .item {
        line-height: 30px;
        color: #657180;
        display: inline-block;
        width: 25%;
      }
    }
    .server-warning-wrap {
      height: 400px;
      position: relative;
    }
  }
</style>

<template>
  <div class="server-monitor">
      <div class="header">
        <fm-breadcrumb>
          <fm-breadcrumb-item :to="{name: 'server.status'}">监控预警</fm-breadcrumb-item>
          <fm-breadcrumb-item>{{server.name}}</fm-breadcrumb-item>
        </fm-breadcrumb>
        <div class="infos">
          <div class="item">
            操作系统: {{server.os}}
          </div>
          <div class="item">
            所属: {{server.belong}}
          </div>
          <div class="item">
            提供商: {{server.provider}}
          </div>
          <div class="item">
            内存: {{config ? config.ram : ''}}
          </div>
          <div class="item">
            CPU: {{config ? config.cpu : ''}}
          </div>
          <div class="item">
            带宽: {{config ? config.internetBandwith : ''}}
          </div>
          <div class="item" style="display: inline-flex;align-items: center;">
            硬盘({{config ? config.disk : ''}}): <card-bar theme="label" style="flex: 1; margin: 0 10px;" :items="[status ? Number((status.serverStatus.diskSpace * 100).toFixed(2)) : 0]" />
          </div>
        </div>
        <fm-btn class="warning-log-btn" @click="showWarning = true">预警记录{{warningNum ? ('(' + warningNum + '条)') : ''}}</fm-btn>
      </div>
      <div class="content">
        <section class="progress">
          <progress-status class="status" :id="id"/>
          <div class="data">
            <progress-list :id="id"/>
            <progress-data style="padding-top: 10px;box-sizing: border-box;" :id="id"/>
          </div>
        </section>
        <!-- <disk-log class="disk" :id="id"/> -->
      </div>
      <fm-modal v-model="showWarning" :width="800">
        <div class="server-warning-wrap">
          <server-warning style="height: 100%;" :id="id" :key="showWarning + id"></server-warning>
        </div>
      </fm-modal>
  </div>
</template>

<script>
import { loadServerStatus, loadServerConfig, registerReloadTimer, removeReloadTimer } from './lib'
import ProgressStatus from './progress/status'
import ProgressList from './progress/list'
import ProgressData from './progress/data'
// import DiskLog from './disk/log'
import ServerWarning from './warning'
import CardBar from '../card/bar'

export default {
  components: { ProgressStatus, ProgressList, ProgressData, /*DiskLog,*/ ServerWarning, CardBar },
  data () {
    return {
      config: null,
      status: null,
      showWarning: false
    }
  },
  computed: {
    id () {
      return Number(this.$route.query.id)
    },
    serverList () {
      return this.$store.getters.serverList
    },
    server () {
      return (this.$store.getters.serverList.find(server => server.key === this.id) || {data: {}}).data
    },
    warningNum () {
      return this.status ? (this.status.warningServerStatus.length + this.status.warningServerPsStatus.length) : 0
    }
  },
  watch: {
    id: {
      immediate: true,
      handler () {
        this.loadData()
        this.loadStatus()
      }
    }
  },
  methods: {
    async loadData () {
      this.config = await loadServerConfig({serverId: this.id})
    },
    async loadStatus (refresh = false) {
      this.status = await loadServerStatus({serverId: this.id}, refresh)
    },
    reload () {
      this.loadStatus(true)
    }
  },
  async mounted () {
    registerReloadTimer(this.reload, 600 * 1000)
    await this.$store.dispatch('loadServerList')
  },
  destroyed () {
    removeReloadTimer(this.reload)
  }
}
</script>
