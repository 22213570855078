<style lang="less">
  .server-progress-list {
    display: flex;
    flex-direction: column;
    .server-progress-title {
      color: #000;
      font-size: 16px;
      padding: 10px;
      font-weight: 800;
    }
    .server-progress-list-table {
      flex: 1;
      position: relative;
    }
  }
</style>

<template>
  <div class="server-progress-list">
    <div class="server-progress-title">当前进程详情</div>
    <div class="server-progress-list-table">
      <fm-table-new :full="true" auto-height :stripe="false" border="row" :columns="columns" :data-list="dataList"></fm-table-new>
    </div>
  </div>
</template>

<script>
import { loadServerStatus, registerReloadTimer, removeReloadTimer } from '../lib'

export default {
  props: {
    id: { type: Number, default: null }
  },
  data () {
    return {
      data: null
    }
  },
  watch: {
    id: {
      immediate: true,
      handler () {
        this.loadData()
      }
    }
  },
  computed: {
    dataList () {
      return this.data ? this.data.serverPsStatus.map(v => {
        return {
          createTime: v.createTime,
          pid: v.pid,
          cpu: v.cpu,
          ram: v.ram,
          command: v.command
        }
      }).sort((a, b) => {
        return (b.cpu + b.ram) - (a.cpu + a.ram)
      }) : []
    },
    columns () {
      return [
        { title: '采集时间', field: 'createTime', align: 'center' },
        { title: '进程ID', field: 'pid', align: 'center' },
        {
          title: 'CPU占用率', field: 'cpu', align: 'center', width: 110, render (h, row) {
            return h('div', (row.cpu * 100).toFixed(2) + '%')
          }, sort: true, dataType: Number
        },
        {
          title: '内存占用率', field: 'ram', align: 'center', width: 110, render (h, row) {
            return h('div', (row.ram * 100).toFixed(2) + '%')
          }, sort: true, dataType: Number
        },
        {
          title: '命令', field: 'command', align: 'center', render: (h, row) => {
            return h('fm-btn', {
              on: {
                click: () => {
                  this.$dialog.info({
                    title: row.pid + ' - 进程详情',
                    content: row.command
                  })
                }
              }
            }, '查看')
          }
        }
      ]
    }
  },
  methods: {
    async loadData (refresh = false) {
      this.data = await loadServerStatus({serverId: this.id}, refresh)
    },
    reload () {
      this.loadData(true)
    }
  },
  mounted () {
    registerReloadTimer(this.reload, 600 * 1000)
  },
  destroyed () {
    removeReloadTimer(this.reload)
  }
}
</script>
